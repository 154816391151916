// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../../node_modules/@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---src-narative-gatsby-theme-novela-templates-article-template-tsx": () => import("./../../src/@narative/gatsby-theme-novela/templates/article.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-article-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */)
}

